import * as React from 'react';
import {navigate} from 'gatsby';
import '../index.scss';
import {Logo} from "../components/Logo";
import {PinHint} from "../components/PinHint";
import {Page} from "../components/Page";
import {track} from "../utils/analytics";

const WelcomePage = () => {
  return <Page title='Welcome'>
    <PinHint/>

    <section className='welcome'>

      <h1>Welcome to <Logo type='inline'/></h1>
      <p className='subtitle' style={{fontSize: 24}}>Your time is valuable, let's get you up and running</p>
      <button onClick={() => {
        track('clicked_get_started');
        navigate('/signup?intent=choose_plan')
      }}>Get started</button>
    </section>
  </Page>;
};

export default WelcomePage;
