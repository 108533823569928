import React, { useState } from 'react';
import s from './PinHint.module.scss';
import pinToChrome from '../assets/images/pin_to_chrome.png'
import {mdiPuzzle, mdiPinOutline, mdiClose} from "@mdi/js";
import {Dot, Icon, Logo} from "./Logo";
import {track} from "../utils/analytics";


export function PinHint() {
  const [show, setShow] = useState(true);

  if (!show) return null;
  return <div className={s.pinHint}>
    <button className='icon' onClick={() => {
      track('clicked_pin_hint', { action: 'close' });
      setShow(false)
    }}>
      <Icon path={mdiClose}/>
    </button>
    <h2>Pin for quick access<Dot/></h2>
    <ol>
      <li>Click the
        <Icon path={mdiPuzzle} type='inline' />
        icon in the toolbar
      </li>
      <li>Then click the
        <Icon path={mdiPinOutline} type='inline' />
        icon to pin <Logo type='inline' darkContrast={true} /> to the toolbar
      </li>
    </ol>
    <img src={pinToChrome} alt="Screenshot hint"/>
  </div>
}